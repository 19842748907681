import React from "react";
import styles from "./VueporteqSEO.module.css";
import TickIcon from "../../../icons/TickIcon";
import img from "./../../../../assets/img/SEOSupport/VueporteqSEO.png";
import TextHeading from "../../../partials/TextHeading/TextHeading";

const points = [
  {
    heading: "Tailored Strategies",
    text: "We customize SEO and SMM strategies to meet your unique business goals and audience needs.",
  },
  {
    heading: "Built-In Integrations",
    text: "Our platform offers seamless integrations with essential marketing tools, speeding up your time-to-market.",
  },
  {
    heading: "Data-Driven Insights",
    text: "Leverage advanced analytics to track performance and refine your campaigns for maximum impact.",
  },
  {
    heading: "Enhanced Online Presence",
    text: "Improve your search engine rankings and social media engagement, driving more organic traffic to your site.",
  },
  {
    heading: "Expert Support",
    text: "Our team of professionals provides ongoing support to ensure you stay ahead of industry trends and competition.",
  },
];

const VueporteqSEO = () => {
  return (
    <section className={styles.section}>
      <TextHeading
        heading="Why Choose Vueporteq for SEO and SMM Support?"
        text="Vueporteq IT Solutions provides a comprehensive, integrated approach to digital marketing, delivering measurable results for your business. Here’s why:"
      />
      <div className={styles.innerContainer}>
        <div className={styles.imageContainer}>
          <img src={img} alt="img" />
        </div>
        <div className={styles.textContainer}>
          <div className={`${styles.pointsContainer} caption`}>
            {points.map((item) => (
              <div className={styles.pointItem}>
                <span>
                  <TickIcon />
                </span>
                <div>
                  <p className={styles.pointItemHeading}>{item.heading}</p>
                  <p className={`fontRoboto ${styles.aboutDescriptionText}`}>
                    {item.text}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default VueporteqSEO;
