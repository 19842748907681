import React, { useEffect, useState } from "react";
import Nav from "./Nav/Nav";
import Footer from "./Footer/Footer";
import Popup from "./Popup/Popup";

const Layout: React.FC<{ children: React.ReactNode | JSX.Element }> = ({
  children,
}) => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const storageDate = localStorage.getItem("showPopup");
    const date = new Date().getDate();
    if (!storageDate || storageDate !== date.toString()) {
      setShowPopup(true);
    }
  }, []);

  return (
    <>
      <Nav />
      <main className="pt-[9rem]">{children}</main>
      <Footer />

      <Popup
        show={showPopup}
        onClose={() => {
          setShowPopup(false);
          localStorage.setItem("showPopup", `${new Date().getDate()}`);
        }}
      />
    </>
  );
};

export default Layout;
