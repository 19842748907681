import React from "react";
import styles from "./ImgTextFlexComponent.module.css";
import TickIcon from "../../icons/TickIcon";

const points = [
  {
    heading: "Vueporteq as your partner",
    text: "With Vueporteq as your partner, create a robust platform that combines high-quality video and audio with seamless collaboration tools.",
  },
  {
    heading: "Our Focus",
    text: "Our focus is to make the platform user-friendly, reliable, and secured to ensure smooth and secure meetings, enabling your team to connect and collaborate effectively, no matter where they are.",
  },
];
const ImgTextFlexComponent: React.FC<{
  MainHeading: string;
  img: string;
  para: string;
  isTitle: boolean;
  btn: boolean;

  imgWidth: string;
}> = ({ MainHeading, img, para, isTitle, btn, imgWidth }) => {
  return (
    <section>
      <div className={styles.innerContainer}>
        <div
          className={imgWidth || styles.imageContainer}
          style={{ maxWidth: imgWidth }}
        >
          <img src={img} alt="img" style={{ width: "100%" }} />
        </div>

        <div className={styles.textContainer}>
          {isTitle && <p className={`body2 ${styles.aboutTitle}`}>About us</p>}
          <h3 className={styles.heading}>{MainHeading}</h3>

          {para && (
            <p
              className={`caption fontRoboto ${styles.aboutDescription} ${styles.aboutDescriptionText}`}
            >
              {para}
            </p>
          )}

          <div className={`${styles.pointsContainer} caption`}>
            {points.map((item) => (
              <div className={styles.pointItem}>
                <span>
                  <TickIcon />
                </span>
                <div>
                  <p className={styles.pointItemHeading}>{item.heading}</p>
                  <p className={`fontRoboto ${styles.aboutDescriptionText}`}>
                    {item.text}
                  </p>
                </div>
              </div>
            ))}
          </div>

          {btn && <button className="btnOutlined">Read More</button>}
        </div>
      </div>
    </section>
  );
};

export default ImgTextFlexComponent;
