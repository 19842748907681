import React from "react";
import BgContainerImg from "../../../partials/BgContainerCard/BgContainerImg";
import img from "./../../../../assets/img/Aiml/AimlHeader.jpg";

const AimlHeader = () => {
  return (
    <>
      <BgContainerImg
        btns={["HOME ", "SERVICES", "AI/ML"]}
        img={img}
        heading="AI/ML"
        isTab={true}
      />
    </>
  );
};

export default AimlHeader;
