import React, { useState } from "react";
import styles from "./SmarterDecisionsTab.module.css";
import Tab1 from "../../../icons/ErpIcons/Tab1";
import Tab2 from "../../../icons/ErpIcons/Tab2";
import Tab3 from "../../../icons/ErpIcons/Tab3";

const TabData = [
  {
    icon: <Tab1 />,
    heading: "Make Better Decisions",
  },
  {
    icon: <Tab2 />,
    heading: "Simplify Your Business",
  },
  {
    icon: <Tab3 />,
    heading: "Run a Global Business With Ease",
  },
];
const SmarterDecisionsTab = () => {
  // const [border, setBorder] useState ("")
  return (
    <div className={styles.innerContainer}>
      <div className={styles.TextContainer}>
        <h3 className={styles.heading}>
          Make smarter decisions faster using our cloud ERP solution
        </h3>
        <h4 className="heading2 caption">
          With Vueporteq’s cloud ERP solution, you can make smarter decisions
          faster by automating processes, enhancing customer relationships, and
          leveraging advanced analytics. Our solution future-proofs your
          business operations, ensuring you remain agile and responsive to the
          evolving market and customer needs.
        </h4>
      </div>
      <div className={styles.TabContainer}>
        {TabData.map((item) => (
          <div className={styles.tabBox}>
            <div className={styles.icon}>{item.icon}</div>
            <h4 className={styles.heading2}>{item.heading}</h4>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SmarterDecisionsTab;
