import React from "react";
import styles from "./LmsPage.module.css";
import BgContainerImg from "../../partials/BgContainerCard/BgContainerImg";
import img from "./../../../assets/img/lms/lms-bg-2.jpg";
import img1 from "./../../../assets/img/lms/lms-about-img.png";
import img2 from "./../../../assets/img/hrms/hrms-recruit-section.png";
import lmsFeaturesImg from "../../../assets/img/lms/lms-features.png";
import chooseLmsImg from "../../../assets/img/lms/why-choose-lms.png";

import TextHeading from "../../partials/TextHeading/TextHeading";
import Icon1 from "../../icons/DocumentManagementSystem/Icon1";
import ClearPricing from "../../icons/DocumentManagementSystem/ClearPricing";
import Support from "../../icons/DocumentManagementSystem/Support";
import EasyScalability from "../../icons/DocumentManagementSystem/EasyScalability";

import Slack from "./../../../assets/img/ERP/slackLogo.png";
import google from "./../../../assets/img/ERP/logos_google.png";
import linkedin from "./../../../assets/img/ERP/logos_linkedin.png";
import trello from "./../../../assets/img/ERP/trello.png";
import RedEx from "./../../../assets/img/DocumentManagementSystem/RedEx-logo.png";
import paypal from "./../../../assets/img/DocumentManagementSystem/logos_paypal.png";
import stripe from "./../../../assets/img/DocumentManagementSystem/logos_stripe.png";
import paytm from "./../../../assets/img/DocumentManagementSystem/paytm.png";
import amazon from "./../../../assets/img/DocumentManagementSystem/amazon.png";
import ErpItSolution1 from "../../icons/ErpIcons/ErpItSolution1";
import ErpItSolution2 from "../../icons/ErpIcons/ErpItSolution2";
import ErpItSolution3 from "../../icons/ErpIcons/ErpItSolution3";
import ErpItSolution4 from "../../icons/ErpIcons/ErpItSolution4";
import Pricing from "../CrmPage/Pricing/Pricing";
import {
  Analytics,
  ArtTrack,
  Dashboard,
  ManageAccounts,
  MeetingRoomTwoTone,
  Payment,
  ReportProblemSharp,
  Timelapse,
  TrackChanges,
} from "@mui/icons-material";
import { TbDeviceAnalytics } from "react-icons/tb";

const data = [
  {
    icon: <Icon1 />,
    heading: "Fast Onboarding",
    pay: `Assistance with all the settings for a fast adoption`,
    BgColor: "#B9E8F2",
  },
  {
    icon: <ClearPricing />,
    heading: "Clear Pricing",
    pay: `Flexible plans for control over your budget`,
    BgColor: "#FFE7B5",
  },
  {
    icon: <Support />,
    heading: "24/7 Support",
    pay: `Help at every step of your digital journey`,
    BgColor: "#FFD1DB",
  },
  {
    icon: <EasyScalability />,
    heading: "Easy Scalability",
    pay: `Scaling up or down, right when you need it`,
    BgColor: "#E0D1FF",
  },
];

const Logo = [
  {
    img: Slack,
  },
  {
    img: google,
  },
  {
    img: trello,
  },
  {
    img: linkedin,
  },
  {
    img: Slack,
  },
  {
    img: RedEx,
  },
  {
    img: paypal,
  },
  {
    img: stripe,
  },
  {
    img: paytm,
  },
  {
    img: amazon,
  },
];

const cardData: {
  icon?: React.ReactNode;
  pay?: string;
  heading: string;
  BgColor?: string;
}[] = [
  {
    icon: <Dashboard />,
    pay: "Centralized view of all HR activities and key performance indicators.",
    heading: "Dashboard",
    BgColor: "#EDEBFD",
  },
  {
    icon: <MeetingRoomTwoTone />,
    pay: "Schedule and manage company events, meetings, and training sessions.",
    heading: "Manage Events and Meetings",
    BgColor: "#FFF1F1",
  },
  {
    icon: <TrackChanges />,
    pay: "Monitor and evaluate employee performance with customizable metrics.",
    heading: "Performance Tracking",
    BgColor: "#FFF1F1",
  },
  {
    icon: <ManageAccounts />,
    pay: "Manage employee data, roles, and responsibilities in a centralized system.",
    heading: "Staff Management",
    BgColor: "#EAEAEA",
  },
  {
    icon: <Payment />,
    pay: "Automate payroll processing, including salary calculation and tax compliance.",
    heading: "Payroll Management",
    BgColor: "#EDEBFD",
  },
  {
    icon: <Timelapse />,
    pay: "Track and manage employee work hours, including overtime and leave.",
    heading: "Timesheet Management",
    BgColor: "#E5FFEC",
  },
  {
    icon: <ArtTrack />,
    pay: "Monitor and manage employee attendance, including leave and absence tracking.",
    heading: "Attendance Module",
    BgColor: "#E5FFEC",
  },
  {
    icon: <TbDeviceAnalytics />,
    pay: "Generate detailed reports on HR metrics, including performance and attendance.",
    heading: "Reports",
    BgColor: "#EAEAEA",
  },
];

const whyChooseLmsData = [
  "Flexibility: Learn anytime, anywhere.",
  "Cost-effective compared to traditional learning.",
  "Personalized learning paths for students.",
  "Scalability for organizations and institutions",
];

const LmsPage = () => {
  return (
    <>
      <BgContainerImg
        btns={["HOME ", "PRODUCTS", "LEARNING MANAGEMENT SYSTEM"]}
        img={img}
        heading="LEARNING MANAGEMENT SYSTEM"
        isTab={true}
      />

      <div className={styles.innerContainer}>
        <div className={`${styles.CardConainer}`}>
          <div className={styles.TextContainer}>
            <h3 className={styles.heading}>Transforming Education Through Innovation</h3>
            <h4 className="heading2 caption">
              A platform that enables the management, delivery, and tracking of
              online learning. Supports digital courses, assessments, learning
              resources, and interaction.
            </h4>
            <button className={`btnPrimary ${styles.btn}`}>GET STARTED</button>
          </div>
          <div className={styles.imgContainer}>
            <img src={img1} alt="" className={styles.img} />
          </div>
        </div>
      </div>
      <div className={styles.border}></div>
      <div className={styles.innerContainer1}>
        <TextHeading
          heading="Why Choose Vueporteq LMS?"
          text="Whether you need to address specific challenges or seek a comprehensive solution, our customizable document management services can streamline your processes and enhance your productivity."
        />
        <div className={styles.imgContainer1}>
          {data.map((item) => (
            <div className={styles.card}>
              <div
                className={styles.bgblueColor}
                style={{ background: item.BgColor }}
              >
                {item.icon}
              </div>

              <h4 className={`${styles.text} body2 fontMontserrat`}>
                {item.heading}
              </h4>
              <p className={`${styles.pay} body1 heading2`}>{item.pay}</p>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.sectionBgColor}>
        <TextHeading heading="Core Features of Our LMS" text="" />
        <div className={styles.innerContainer3}>
          <img src={lmsFeaturesImg} alt="" className={styles.lmsFeatures} />
          {/* <img src={img2} alt="" className={styles.CustomizeImg} />
          <div className={styles.TextContainer1}>
            <h3 className={styles.heading}>
              Recruit New Candidates and Grow Your Team
            </h3>
            <h4 className="heading2 caption">
              Collect and manage applications from start to finish. Easily
              compare candidates and pick the best one for the job.
            </h4>

            <button className={`btnOutlined ${styles.btn1}`}>FREE DEMO</button>
          </div> */}
        </div>
      </div>
      <section className={styles.borderBottom}>
        <div className={styles.innerContainer4}>
          <TextHeading
            heading="Holistic Integration Capabilities"
            text="Vueporteq IT Solutions has a host of built-in integrations that help your business take-off faster."
          />

          <div className={styles.cardContainer2}>
            {Logo.map((item) => (
              <div className={styles.card1}>
                <img src={item.img} alt="" className={styles.imgLogo} />
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className={styles.sectionBgColor}>
        <TextHeading heading="Why Choose Our LMS?" text="" />
        <div className={`${styles.innerContainer3} ${styles.whyLmsInnerContainer}`}>
          <div className={`${styles.whyChooseLmsList}`}>
            {whyChooseLmsData.map((item, i) => (
              <div key={i}>
                <h3>0{i + 1}</h3>
                <p>{item}</p>
              </div>
            ))}
          </div>
          <img src={chooseLmsImg} alt="lms" />
        </div>
      </section>

      <section>
        <div className={styles.innerContainer5}>
          <TextHeading
            heading="Features"
            text="Seamlessly upload, organize, and access documents from anywhere with our intuitive cloud-based document management system."
          />
          <div className={styles.cardContainer1}>
            {cardData.map((item, i) => (
              <div className={styles.cards1}>
                {item.icon && (
                  <div
                    className={styles.bgblueColor1}
                    style={{ background: item.BgColor }}
                  >
                    {item.icon}
                  </div>
                )}
                <div>
                  <p className={styles.heading1}>{item.heading}</p>
                  {item.pay && (
                    <p className={`${styles.pay} heading2`}>{item.pay}</p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* <Pricing /> */}
    </>
  );
};

export default LmsPage;
