import React from "react";

import { GiMining } from "react-icons/gi";
import { BiChip, BiTestTube } from "react-icons/bi";
import { TbMathFunction, TbPresentationAnalytics } from "react-icons/tb";
import {
  BsTools,
  BsShieldFillCheck,
  BsFillGrid1X2Fill,
  BsSpeedometer,
} from "react-icons/bs";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import {
  AiOutlineStock,
  AiOutlineClockCircle,
  AiOutlineCalendar,
  AiOutlineDatabase,
  AiOutlineControl,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import Industries from "../components/app/BackOffice/Industries";

const BackOfficePage = () => {
  return (
    <>
      {/* Hero Section STARTS */}
      <section className="text-gray-600 body-font">
        <div className=" mx-auto flex px-5 sm:py-10 sm:mx-10 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:text-left mb-16 md:mb-0 items-start">
            <h1 className="title-font text-3xl max-md:mt-10 md:leading-[7.4rem] sm:text-[70px] font-bold mb-4 text-gray-900">
              Back-Office <span className="text-primary"> Support</span>
            </h1>
            <p className="mb-8 text-center text-2xl md:text-left">
              Great things in business are never done by one person, they are
              done by a team of people
            </p>
            <div className="flex sm:justify-center md:justify-start sm:gap-8 w-full">
              <Link to="/contact">
                <button className="p-2 bg-primary text-white rounded-sm hover:scale-105 transition-all">
                  Talk to our Consultant
                </button>
              </Link>
              <Link to="/projects">
                <button className="p-2 shadow-xl rounded-sm text-heading hover:scale-105 transition-all">
                  Our Portfolio
                </button>
              </Link>
            </div>
          </div>

          <div className="">
            <img
              className="object-contain object-center rounded sm:h-[500px]"
              alt="hero"
              src="/backgrounds/backofficebanner.jpeg"
            />
          </div>
        </div>
      </section>
      {/* Hero Section ENDS */}

      {/* Web Apps start------------------------------------------------------------ */}
      <section className=" bg-lightGray my-10">
        <div className="max-w-screen-2xl mx-auto grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 ">
          <div className="flex flex-col">
            <div className="title text-left mx-7 my-9 text-2xl sm:text-3xl md:text-4xl lg:text-4xl">
              <h1 className="font-bold leading-[4.5rem]">
                Range of <span className="text-primary">Support services</span>
              </h1>
            </div>

            <p className="text-[1.6rem] text-left mx-7">
              Keep your website/app fully functional with expert support
              services from Vueporteq. We provide highly competent quality
              support experts, so you can focus on your core business.
              <br /> <br />
              <div className="flex flex-col sm:flex-row gap-14">
                <div className="flex flex-col gap-5">
                  <li className="flex gap-2  items-center">
                    <BsShieldFillCheck size={20} className="text-primary" />
                    Data Mining
                  </li>
                  <li className="flex gap-2  items-center">
                    <TbPresentationAnalytics
                      size={20}
                      className="text-primary"
                    />
                    Data Analytics
                  </li>
                  <li className="flex gap-2  items-center">
                    <BsFillGrid1X2Fill size={18} className="text-primary" />
                    GUI and Usability Testing
                  </li>
                  <li className="flex gap-2  items-center">
                    <BiTestTube size={20} className="text-primary" />
                    Compatibility Testing
                  </li>
                  <li className="flex gap-2  items-center">
                    <BiChip size={20} className="text-primary" />
                    System / Integration Testing
                  </li>
                </div>
                <div className="flex flex-col gap-5">
                  <li className="flex gap-2  items-center">
                    <AiOutlineDatabase size={20} className="text-primary" />
                    Data Entry
                  </li>
                  <li className="flex gap-2  items-center">
                    <TbMathFunction size={20} className="text-primary" />
                    Functional and Regression Testing
                  </li>
                  <li className="flex gap-2  items-center">
                    <AiOutlineControl size={20} className="text-primary" />
                    Quality control
                  </li>
                  <li className="flex gap-2  items-center">
                    <BsSpeedometer size={20} className="text-primary" />
                    Performance Testing
                  </li>
                  <li className="flex gap-2  items-center">
                    <BsTools size={20} className="text-primary" />
                    Technical testing
                  </li>
                </div>
              </div>
            </p>
          </div>
          <div className="flex flex-col my-10 mx-6">
            <img src="/imgs/backoffice1.png" width={500} height={400} />
          </div>
        </div>
      </section>
      {/* Web Apps End-------------------------------------------------------------------------- */}

      {/* Benefit section STARTS  */}
      <section className=" my-10">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 ">
          {/* first section */}
          <div className="flex flex-col items-center my-10 mx-6">
            <img src="/imgs/backoffice2.jpeg" width={400} height={400} />
          </div>
          {/* second section */}
          <div className="flex flex-col">
            <div className="title text-left mx-7 my-9 text-2xl sm:text-3xl md:text-4xl lg:text-4xl">
              <h1 className="font-bold leading-[4.5rem]">
                Benefits of{" "}
                <span className="text-primary">Choosing Vueporteq</span>
              </h1>
            </div>

            <div className="mx-7 mb-10">
              <p className="text-[1.6rem] text-left ">
                Vueporteq’s Services reduce the time and effort in testing and
                other non core activities for your organisation so that you can
                shorten the Time To Market (TTM) for your products, while
                improving your Return on Investment (ROI) with enhanced support
                for your business.
              </p>
              <br />
              <div className="flex flex-col gap-3">
                <li className="flex sm:items-center gap-2">
                  <GiMining size={20} className="text-primary mt-2 sm:mt-0" />
                  Use of the best tools and Process driven.
                </li>
                <li className="flex sm:items-center gap-2">
                  <AiOutlineStock
                    size={24}
                    className="text-primary mt-2 sm:mt-0"
                  />
                  Improve the quality, reliability and performance of your
                  Product
                </li>
                <li className="flex sm:items-center gap-2">
                  <AiOutlineClockCircle
                    size={20}
                    className="text-primary mt-2 sm:mt-0"
                  />
                  Reduce ROI (Return On Investment) through our support
                  services.
                </li>
                <li className="flex sm:items-center gap-2">
                  <HiOutlineDocumentDuplicate
                    size={20}
                    className="text-primary mt-2 sm:mt-0"
                  />
                  Daily reporting and access to the team.
                </li>
                <li className="flex sm:items-center gap-2">
                  <AiOutlineCalendar
                    size={20}
                    className="text-primary mt-2 sm:mt-0"
                  />
                  Quality planning and results.
                </li>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Benefit section ENDS  */}

      <Industries />
    </>
  );
};

export default BackOfficePage;
