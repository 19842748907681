// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BgContainerImg_bgContainer__Nn0CY {
  /* height: 323px; */
  /* margin-bottom: 6rem; */
}
.BgContainerImg_bgImg__oxa7N {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 400px;
  overflow: hidden;
}
.BgContainerImg_img__1\\+YUP {
  width: 100%;
  min-height: 220px;
}
.BgContainerImg_textHeading__qs6Fy {
  color: #ffff;
  font-size: 4rem;
  position: absolute;
  text-align: center;
}

.BgContainerImg_buttonContainer__PnpB- {
  background-color: #232323;
  opacity: 60%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* max-width: 27.9rem; */
  margin: auto;
  margin-top: 1.5rem;
}
.BgContainerImg_btns__50\\+CX {
  padding: 1rem 2rem;
  color: #ffff;
  background-color: transparent;
  font-weight: 600;
}
.BgContainerImg_border__frhc3 {
  font-size: 2.3rem;
}

.BgContainerImg_activeBtnClass__dzNlE {
  color: #0d71ac;
}
`, "",{"version":3,"sources":["webpack://./src/components/partials/BgContainerCard/BgContainerImg.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,wBAAwB;EACxB,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,6BAA6B;EAC7B,gBAAgB;AAClB;AACA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".bgContainer {\n  /* height: 323px; */\n  /* margin-bottom: 6rem; */\n}\n.bgImg {\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  max-height: 400px;\n  overflow: hidden;\n}\n.img {\n  width: 100%;\n  min-height: 220px;\n}\n.textHeading {\n  color: #ffff;\n  font-size: 4rem;\n  position: absolute;\n  text-align: center;\n}\n\n.buttonContainer {\n  background-color: #232323;\n  opacity: 60%;\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  /* max-width: 27.9rem; */\n  margin: auto;\n  margin-top: 1.5rem;\n}\n.btns {\n  padding: 1rem 2rem;\n  color: #ffff;\n  background-color: transparent;\n  font-weight: 600;\n}\n.border {\n  font-size: 2.3rem;\n}\n\n.activeBtnClass {\n  color: #0d71ac;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bgContainer": `BgContainerImg_bgContainer__Nn0CY`,
	"bgImg": `BgContainerImg_bgImg__oxa7N`,
	"img": `BgContainerImg_img__1+YUP`,
	"textHeading": `BgContainerImg_textHeading__qs6Fy`,
	"buttonContainer": `BgContainerImg_buttonContainer__PnpB-`,
	"btns": `BgContainerImg_btns__50+CX`,
	"border": `BgContainerImg_border__frhc3`,
	"activeBtnClass": `BgContainerImg_activeBtnClass__dzNlE`
};
export default ___CSS_LOADER_EXPORT___;
