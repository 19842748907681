import React from "react";
import TextHeading from "../../../partials/TextHeading/TextHeading";
import styles from "./ErpItSolutions.module.css";
import ErpItSolution1 from "../../../icons/ErpIcons/ErpItSolution1";
import ErpItSolution2 from "../../../icons/ErpIcons/ErpItSolution2";
import ErpItSolution3 from "../../../icons/ErpIcons/ErpItSolution3";
import ErpItSolution4 from "../../../icons/ErpIcons/ErpItSolution4";

const cardData = [
  {
    icon: <ErpItSolution1 />,
    heading: "Core Manufacturing ERP System",
    pay: "Vueporteq IT Solutions delivers the benefits of modern enterprise resource planning - visibility into what you need, when, and where you need it.",
    BgColor: "#EDEBFD",
  },
  {
    icon: <ErpItSolution2 />,
    heading: "Integrate and Automate",
    pay: "Drive net profits with efficient workflows and increase productivity with innovative integrations to track, manage, and measure processes.",
    BgColor: "#FFF1F1",
  },
  {
    icon: <ErpItSolution3 />,
    heading: "Manage, Streamline, Optimize",
    pay: "Best-in-breed ERP for manufacturers helps you crack new markets, maximize sales, increase cashflow, simplify inventory, and mitigate supply chain disruptions.",
    BgColor: "#E5FFEC",
  },
  {
    icon: <ErpItSolution4 />,
    heading: "Modern, Scalable & Cloud-Based",
    pay: "The modern ERP technology you need to achieve sustainable growth and a competitive edge -- with simple, intuitive, user-friendly tools that future-proof your expansion.",
    BgColor: "#EAEAEA",
  },
];
const ErpitSolutions = () => {
  return (
    <section className={styles.borderBottom}>
      <div className={styles.innerContainer}>
        <TextHeading
          heading="Why Choose Vueporteq IT Solutions?"
          text="Choose Vueporteq IT Solutions for your ERP needs to benefit from customized solutions that streamline operations, enhance productivity, and drive sustainable growth."
        />
        <div className={styles.cardContainer}>
          {cardData.map((item, i) => (
            <div className={styles.cards}>
              <div
                className={styles.bgblueColor}
                style={{ background: item.BgColor }}
              >
                {item.icon}
              </div>
              <div>
                <p className={styles.heading}>{item.heading}</p>
                <p className={`${styles.pay} heading2`}>{item.pay}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ErpitSolutions;
