import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./AppWebDevelpment.css";
import App from "./App";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Layout from "./components/layout/Layout";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import CrmPages from "./pages/CrmPages";
import AimlPage from "./pages/AimlPage";
import SeoSupportPage from "./pages/SeoSupportPage";
import MtcPage from "./pages/MtcPage";
import CrmProductPage from "./pages/CrmProductPage";
import ErpPage from "./pages/ErpPage";
import WebConferenceSolutions from "./pages/WebConferenceSolutions";
import AvidianCRM from "./pages/AvidianCRM";
import NssplAvidianCrm from "./pages/NssplAvidianCrm";
import NssplMtc from "./pages/NssplMtc";
import CaseStudyHeader from "./components/app/CaseStudyPage/CaseStudyHeader";
import CaseStudies from "./pages/CaseStudies";
import FiservOndot from "./components/app/CaseStudyPage/FiservOndot/FiservOndot";
import CaseStudyPageComponet from "./components/app/CaseStudyPage/CaseStudyPageComponet/CaseStudyPageComponet";
import Premon from "./components/app/CaseStudyPage/Premon/Premon";
import Sport from "./components/app/CaseStudyPage/sport/Sport";
import Appdev from "./pages/Appdev";
import WebDev from "./pages/WebDev";
import Modifi from "./components/app/CaseStudyPage/Modifi/Modifi";
import AllNewAgain from "./components/app/CaseStudyPage/AllNewAgain/AllNewAgain";
import AvidianPage from "./pages/AvidianPage";
import DocumentManagementSystem from "./components/app/DocumentManagementPage/DocumentManagementSystem";
import BlockchainPage from "./pages/BlockchainPage";
import ContractDeveloperPage from "./pages/ContractDeveloperPage";
import BackOfficePage from "./pages/BackOfficePage";
import HrmsPage from "./components/app/HrmsPage/HrmsPage";
import LmsPage from "./components/app/LmsPage/LmsPage";

const HomePage = lazy(() => import("./pages/HomePage"));

const HeaderLayout = () => (
  <>
    <Suspense
      fallback={
        // <div></div>
        <div className="min-h-[80vh] flex justify-center items-center">
          {/* <LoadingScreen /> */}
          Loading...
        </div>
      }
    >
      <Layout>
        <Outlet />
      </Layout>
    </Suspense>
  </>
);

const router = createBrowserRouter([
  {
    element: <HeaderLayout />,
    children: [
      {
        path: "/",
        loader: () => "loading",
        element: <HomePage />,
      },

      // Services
      {
        path: "/crm",
        loader: () => "loading",
        element: <CrmPages />,
      },
      {
        path: "/aiml",
        loader: () => "loading",
        element: <AimlPage />,
      },
      {
        path: "/erp",
        loader: () => "loading",
        element: <ErpPage />,
      },
      {
        path: "/web-conference-solutions",
        loader: () => "loading",
        element: <WebConferenceSolutions />,
      },
      {
        path: "/seo-Support",
        loader: () => "loading",
        element: <SeoSupportPage />,
      },

      // Product
      {
        path: "/avidian-crm",
        loader: () => "loading",
        element: <AvidianCRM />,
      },
      {
        path: "/mtc",
        loader: () => "loading",
        element: <MtcPage />,
      },
      {
        path: "/product/crm",
        loader: () => "loading",
        element: <CrmProductPage />,
      },
      // Case studies

      {
        path: "/case-study",
        loader: () => "loading",
        element: <CaseStudies />,
      },

      {
        path: "/fiser-vondot",
        loader: () => "loading",
        element: <FiservOndot />,
      },
      {
        path: "/premon",
        loader: () => "loading",
        element: <Premon />,
      },
      {
        path: "/sport",
        loader: () => "loading",
        element: <Sport />,
      },
      {
        path: "/modifi",
        loader: () => "loading",
        element: <Modifi />,
      },
      {
        path: "/all-new-again",
        loader: () => "loading",
        element: <AllNewAgain />,
      },

      {
        path: "/about",
        loader: () => "loading",
        element: <AboutPage />,
      },
      {
        path: "/contact",
        loader: () => "loading",
        element: <ContactPage />,
      },
      {
        path: "/nsspl-Avidian-Crm",
        loader: () => "loading",
        element: <NssplAvidianCrm />,
      },
      {
        path: "/nsspl-mtc",
        loader: () => "loading",
        element: <NssplMtc />,
      },
      {
        path: "/services/appdev",
        loader: () => "loading",
        element: <Appdev />,
      },
      {
        path: "/services/webdev",
        loader: () => "loading",
        element: <WebDev />,
      },
      {
        path: "/avidian",
        loader: () => "loading",
        element: <AvidianPage />,
      },
      {
        path: "/dms",
        loader: () => "loading",
        element: <DocumentManagementSystem />,
      },
      {
        path: "/blockchain",
        loader: () => "loading",
        element: <BlockchainPage />,
      },
      {
        path: "/contract-developer",
        loader: () => "loading",
        element: <ContractDeveloperPage />,
      },
      {
        path: "/back-office",
        loader: () => "loading",
        element: <BackOfficePage />,
      },
      {
        path: "/hrms",
        loader: () => "loading",
        element: <HrmsPage />,
      },
      {
        path: "/lms",
        loader: () => "loading",
        element: <LmsPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<RouterProvider router={router} />);
