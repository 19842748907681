import React from "react";
import styles from "./RankHigher.module.css";
import img from "./../../../../assets/img/SEOSupport/Seorank.png";
import { Link } from "react-router-dom";

const RankHigher = () => {
  return (
    <section className={styles.borderBottom}>
      <div className={styles.innerContainer}>
        <div className={styles.TextContainer}>
          <h3 className={styles.heading}>
            Rank Higher with Better SEO and SMM Support
          </h3>
          <h4 className="heading2 caption">
            With Vueporteq’s SEO and SMM support services, you can elevate your
            online presence, enhance customer engagement, and drive targeted
            traffic to your website. Our expert strategies ensure that your
            brand remains visible and competitive in the digital landscape,
            adapting to the ever-changing trends and preferences of your
            audience. By leveraging our SEO and social media marketing
            expertise, you can unlock new opportunities for growth, increase
            brand awareness, and connect with your customers more effectively.
            Maximize the potential of your online assets and harness the power
            of data-driven insights to create impactful campaigns that resonate
            with your target market.
          </h4>
          <Link to={"/contact"}>
            <button className={`btnPrimary ${styles.btn}`}>GET STARTED</button>
          </Link>
        </div>

        <img src={img} alt="" className={styles.img} />
      </div>
    </section>
  );
};

export default RankHigher;
