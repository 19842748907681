import React from "react";
import TextHeading from "../../../partials/TextHeading/TextHeading";
import styles from "./SeoServices.module.css";
import SeoIcon1 from "../../../icons/SeoIcon1";
import SeoIcon2 from "../../../icons/SeoIcon2";
import SeoIcon3 from "../../../icons/SeoIcon3";

const data = [
  {
    icon: <SeoIcon1 />,
    heading: "SEO Audits",
    pay: "Get comprehensive analysis of your website's performance, identifying areas for improvement in search engine rankings and user experience.  We assess factors like keyword usage, site structure, and backlink quality to deliver actionable insights that enhance your online visibility and drive organic traffic.",
  },
  {
    icon: <SeoIcon1 />,
    heading: "SEO Strategy",
    pay: "A customized approach to boost your online visibility and rankings. Get tailored plans that focus on keyword optimization, content creation, and competitor analysis, ensuring your website attracts relevant traffic and achieves long-term success in search engine results.",
  },
  {
    icon: <SeoIcon2 />,
    heading: "Analysis",
    pay: "Get in-depth insights into your online performance across search engines and social media platforms. Evaluate key metrics such as engagement, traffic, and conversion rates to identify strengths and areas for improvement, helping you refine your strategy and maximize your digital marketing impact.",
  },
  {
    icon: <SeoIcon3 />,
    heading: "Social Media Strategy",
    pay: "Craft targeted plans to enhance your brand's presence and engagement across social platforms. The focus is kept on audience insights, content optimization, and platform-specific tactics to build strong connections with your followers, drive traffic, and increase conversions, ensuring long-term success in your digital marketing efforts.",
  },
];

const SeoServices = () => {
  return (
    <section>
      <div className={styles.innerContainer}>
        <TextHeading
          heading="What do our SEO and SMM services include?"
          text="Whether you’re looking to fill gaps or find a turn-key solution, our customizable search engine optimization and social media marketing services can help."
        />
        <div className={styles.imgContainer}>
          {data.map((item) => (
            <div className={styles.card}>
              {item.icon}
              {/* <SeoIcon3 /> */}

              <h4 className={`${styles.text} body1 heading2`}>
                {item.heading}
              </h4>
              <p className={`${styles.pay} heading2`}>{item.pay}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SeoServices;
