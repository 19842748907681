import React from "react";
import styles from "./SocialMedia.module.css";
import img from "./../../../../assets//img/SEOSupport/Social.png";

const SocialMedia = () => {
  return (
    <section className={styles.sectionBgColor}>
      <div className={styles.innerContainer}>
        <img src={img} alt="" className={styles.img} />

        <div className={styles.TextContainer}>
          <h3 className={styles.heading}>
            Social media tools at your fingertips
          </h3>
          <h4 className="heading2 caption">
          Access powerful social media tools that simplify content scheduling, engagement tracking, and performance analysis, all at your fingertips. Maximize your social media impact with ease and efficiency.
          </h4>
          <button className={`btnPrimary ${styles.btn}`}>FREE DEMO</button>
        </div>
      </div>
    </section>
  );
};

export default SocialMedia;
