import React from "react";
import styles from "./DocumentManagementSystem.module.css";
import BgContainerImg from "../../partials/BgContainerCard/BgContainerImg";
import img from "./../../../assets/img/DocumentManagementSystem/header.png";
import img1 from "./../../../assets/img/DocumentManagementSystem/img1.png";
import img2 from "./../../../assets/img/DocumentManagementSystem/img2.png";

import TextHeading from "../../partials/TextHeading/TextHeading";
import Icon1 from "../../icons/DocumentManagementSystem/Icon1";
import ClearPricing from "../../icons/DocumentManagementSystem/ClearPricing";
import Support from "../../icons/DocumentManagementSystem/Support";
import EasyScalability from "../../icons/DocumentManagementSystem/EasyScalability";

import Slack from "./../../../assets/img/ERP/slackLogo.png";
import google from "./../../../assets/img/ERP/logos_google.png";
import linkedin from "./../../../assets/img/ERP/logos_linkedin.png";
import trello from "./../../../assets/img/ERP/trello.png";
import RedEx from "./../../../assets/img/DocumentManagementSystem/RedEx-logo.png";
import paypal from "./../../../assets/img/DocumentManagementSystem/logos_paypal.png";
import stripe from "./../../../assets/img/DocumentManagementSystem/logos_stripe.png";
import paytm from "./../../../assets/img/DocumentManagementSystem/paytm.png";
import amazon from "./../../../assets/img/DocumentManagementSystem/amazon.png";
import ErpItSolution1 from "../../icons/ErpIcons/ErpItSolution1";
import ErpItSolution2 from "../../icons/ErpIcons/ErpItSolution2";
import ErpItSolution3 from "../../icons/ErpIcons/ErpItSolution3";
import ErpItSolution4 from "../../icons/ErpIcons/ErpItSolution4";
import Pricing from "../CrmPage/Pricing/Pricing";

const data = [
  {
    icon: <Icon1 />,
    heading: "Fast Onboarding",
    pay: `Assistance with all the settings for a fast adoption`,
    BgColor: "#B9E8F2",
  },
  {
    icon: <ClearPricing />,
    heading: "Clear Pricing",
    pay: `Flexible plans for control over your budget`,
    BgColor: "#FFE7B5",
  },
  {
    icon: <Support />,
    heading: "24/7 Support",
    pay: `Help at every step of your digital journey`,
    BgColor: "#FFD1DB",
  },
  {
    icon: <EasyScalability />,
    heading: "Easy Scalability",
    pay: `Scaling up or down, right when you need it`,
    BgColor: "#E0D1FF",
  },
];

const Logo = [
  {
    img: Slack,
  },
  {
    img: google,
  },
  {
    img: trello,
  },
  {
    img: linkedin,
  },
  {
    img: Slack,
  },
  {
    img: RedEx,
  },
  {
    img: paypal,
  },
  {
    img: stripe,
  },
  {
    img: paytm,
  },
  {
    img: amazon,
  },
];

const cardData = [
  {
    icon: <ErpItSolution1 />,
    heading: "Synchronization",
    pay: "Synchronization to establish automatic or on-demand links between your local device and remote directories. Also, synchronize to Google Docs and Amazon Cloudfront.",
    BgColor: "#EDEBFD",
  },
  {
    icon: <ErpItSolution2 />,
    heading: "Work-Flow and Collaborate",
    pay: "Work-Flow and Collaborate for creating Discussions and Workflow Documents between users.",
    BgColor: "#FFF1F1",
  },
  {
    icon: <ErpItSolution3 />,
    heading: "Advance Drag and Drop",
    pay: "Allows for the transferring of files between servers, your PC, Tablet or Smart Phone using easily accessible bookmarks. ",
    BgColor: "#E5FFEC",
  },
  {
    icon: <ErpItSolution4 />,
    heading: "Document and User Access Control",
    pay: "Supporting copy, move, archive, delete and change ownership of documents and folders. Bulk Upload and Download features allow you to transfer and exchange large folders and files. ",
    BgColor: "#EAEAEA",
  },
];
const DocumentManagementSystem = () => {
  return (
    <>
      <BgContainerImg
        btns={["HOME ", "PRODUCTS", "DOCUMENT MANAGEMENT SYSTEM"]}
        img={img}
        heading="DOCUMENT MANAGEMENT SYSTEM"
        isTab={true}
      />

      <div className={styles.innerContainer}>
        <div className={`${styles.CardConainer}`}>
          <div className={styles.TextContainer}>
            <h3 className={styles.heading}>
              The Go-To Tool to Calm Your Operational Chaos
            </h3>
            <h4 className="heading2 caption">
              Transform your document-dependent work from a burdensome task to a
              powerful source of growth and positive impact.
            </h4>
            <button className={`btnPrimary ${styles.btn}`}>GET STARTED</button>
          </div>
          <div className={styles.imgContainer}>
            <img src={img1} alt="" className={styles.img} />
          </div>
        </div>
      </div>
      <div className={styles.border}></div>
      <div className={styles.innerContainer1}>
        <TextHeading
          heading="Why Why Choose Vueporteq DMS?"
          text="Whether you need to address specific challenges or seek a comprehensive solution, our customizable document management services can streamline your processes and enhance your productivity."
        />
        <div className={styles.imgContainer1}>
          {data.map((item) => (
            <div className={styles.card}>
              <div
                className={styles.bgblueColor}
                style={{ background: item.BgColor }}
              >
                {item.icon}
              </div>

              <h4 className={`${styles.text} body2 fontMontserrat`}>
                {item.heading}
              </h4>
              <p className={`${styles.pay} body1 heading2`}>{item.pay}</p>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.sectionBgColor}>
        <div className={styles.innerContainer3}>
          <img src={img2} alt="" className={styles.CustomizeImg} />
          <div className={styles.TextContainer1}>
            <h3 className={styles.heading}>
              Improved efficiency and transparency
            </h3>
            <h4 className="heading2 caption">
              Our workflow engine helps businesses with higher document
              circulation and if there are chances of losing the documents. So,
              if you want to reduce time consumption, improve staff coordination
              and management, and ensure improved business development, our
              workflow engine will help. Start now, improve efficiency and
              transparency with us!
            </h4>

            <button className={`btnOutlined ${styles.btn1}`}>FREE DEMO</button>
          </div>
        </div>
      </div>
      <section className={styles.borderBottom}>
        <div className={styles.innerContainer4}>
          <TextHeading
            heading="Holistic Integration Capabilities"
            text="Vueporteq IT Solutions has a host of built-in integrations that help your business take-off faster."
          />

          <div className={styles.cardContainer2}>
            {Logo.map((item) => (
              <div className={styles.card1}>
                <img src={item.img} alt="" className={styles.imgLogo} />
              </div>
            ))}
          </div>
        </div>
      </section>

      <section>
        <div className={styles.innerContainer5}>
          <TextHeading
            heading="Features"
            text="Seamlessly upload, organize, and access documents from anywhere with our intuitive cloud-based document management system."
          />
          <div className={styles.cardContainer1}>
            {cardData.map((item, i) => (
              <div className={styles.cards1}>
                <div
                  className={styles.bgblueColor1}
                  style={{ background: item.BgColor }}
                >
                  {item.icon}
                </div>
                <div>
                  <p className={styles.heading1}>{item.heading}</p>
                  <p className={`${styles.pay} heading2`}>{item.pay}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* <Pricing /> */}
    </>
  );
};

export default DocumentManagementSystem;
