import React from "react";
import styles from "./Popup.module.css";
import img from "../../../assets/img/popup-image.png";
import CloseIcon from "../../icons/CloseIcon";

const Popup: React.FC<{ show: boolean; onClose: () => void }> = ({
  show,
  onClose,
}) => {
  return (
    <>
      <div
        id={show ? "mainPopup" : ""}
        className={`${styles.popup} ${show ? styles.showPopup : ""}`}
      >
        <button className={styles.closeButton} onClick={onClose}>
          <CloseIcon />
        </button>
        <div className={styles.imageContainer}>
          <img src={img} alt="popup-image" />
        </div>
        <a
          href="https://nsspl-businiess-card.web.app/arjun-vaidya"
          target="_blank"
          className={styles.joinButton}
          onClick={onClose}
        >
          Join Now
        </a>
      </div>
      <div
        className={`${styles.popupOverlay} ${show ? styles.showOverlay : ""}`}
        onClick={onClose}
      ></div>
    </>
  );
};

export default Popup;
