import React from "react";
import styles from "./ErpSystem.module.css";
import img from "./../../../../assets/img/SEOSupport/monitor.png";

const ErpSystem = () => {
  return (
    <div className={styles.innerContainer}>
      <div className={styles.TextContainer}>
        <h3 className={styles.heading}>
          Customize, upscale, and deploy with ease
        </h3>
        <h4 className="heading2 caption">
          Our ERP system offers a comprehensive suite of features tailored for
          various businesses, especially those operating in wholesale,
          distribution, trading, construction, and manufacturing sectors. When
          integrated with a third-party interactive desktop Point of Sale system
          featuring a rewards program, it becomes the central hub for managing a
          multi-branch retail operation, streamlining processes and enhancing
          overall efficiency.
        </h4>
        <button className={`btnPrimary ${styles.btn}`}>FREE DEMO</button>
      </div>
      <img src={img} alt="" className={styles.img} />
    </div>
  );
};

export default ErpSystem;
