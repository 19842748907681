import React, { useRef, useState } from "react";
import styles from "./Footer.module.css";
import logo from "../../../assets/img/logo.png";
import SendIcon from "../../icons/SendIcon";
import {
  AccessTime,
  DraftsOutlined,
  LocationOn,
  PhoneEnabledOutlined,
} from "@mui/icons-material";

import FacebookIcon from "../../icons/FacebookIcon";
import TwitterIcon from "../../icons/TwitterIcon";
import LinkedinIcon from "../../icons/LinkedinIcon";
import InstaIcon from "../../icons/InstaIcon";
import { Link, useLocation } from "react-router-dom";
import {
  companyEmail,
  phoneNumber,
  workingDays,
} from "../../../helper/constant";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";

const linksData = [
  {
    heading: "Useful Links",
    links: [
      { link: "#", text: "Home" },
      { link: "#", text: "Services" },
      { link: "#", text: "Products" },
      { link: "#", text: "Case Studies" },
      { link: "#", text: "About" },
      { link: "#", text: "Contact" },
    ],
  },

  {
    heading: "Our Services",
    links: [
      { link: "/aiml", text: "AI/ML" },
      { link: "/services/appdev", text: "App Development" },
      { link: "/services/webdev", text: "Web Development" },
      // { link: "#", text: "Legacy System Upgrade/ Management" },
      { link: "#", text: "Cloud Computing" },
      { link: "/seo-support", text: "SEO/SMM Support" },
    ],
  },

  {
    heading: "Our Products",
    links: [
      { link: "/dms", text: "DMS" },
      // { link: "/crm", text: "CRM" },
      { link: "/erp", text: "ERP" },
      { link: "/web-conference-solutions", text: "Web Conference Solutions" },
      { link: "/avidian-crm", text: "Avidian CRM" },
    ],
  },
];

const addressData = [
  { icon: <LocationOn />, text: "Dubai, UAE", link: "#" },
  { icon: <AccessTime />, text: `Office Hours ${workingDays}` },
  {
    icon: <DraftsOutlined />,
    text: `Email: ${companyEmail}`,
    link: `mailto:${companyEmail}`,
  },
  {
    icon: <PhoneEnabledOutlined />,
    text: `Phone number : ${phoneNumber}`,
    link: `tel:${phoneNumber.replaceAll(" ", "")}`,
  },
];

const socialIcons = [
  { icon: <FacebookIcon />, link: "#" },
  { icon: <TwitterIcon />, link: "#" },
  { icon: <LinkedinIcon />, link: "#" },
  { icon: <InstaIcon />, link: "#" },
];

const copyRightLinks = [
  { link: "#", text: "Terms of Use" },
  { link: "#", text: "License" },
  { link: "#", text: "Support" },
];

const Footer = () => {
  const emailRef = useRef<HTMLInputElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { pathname } = useLocation();

  const formSubmitHandler = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isLoading) return;

    const formBody = {
      name: "",
      email: emailRef.current?.value,
      mobile: "",
      aboutus: "Contact-us from Footer",
      inquiryType: "",
      companyName: "",
    };

    console.log("formBody", formBody);

    setIsLoading(true);

    axios
      .post("https://www.vueporteq.ae/api/contactUs", formBody, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("res", res);
        setIsLoading(false);
        toast("Your message has been sent successfully!", { type: "success" });

        emailRef.current!.value = "";
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const pages = ["/crm", "/web-conference-solutions", "/dms"];

  return (
    <>
      {pages.includes(pathname) && (
        <div className={styles.poweredByAvidian}>
          <p>Powered by Avidian</p>
        </div>
      )}
      <div
        style={pages.includes(pathname) ? { marginTop: "4rem" } : {}}
        className={styles.textHeadingContainer}
      >
        <h3 className={styles.heading}>
          Get Started with Vueporteq IT Solutions
        </h3>
        <div className={styles.borderContainer}>
          <span className={styles.BoderBlue}></span>
          <span className={styles.BoderWhite}></span>
        </div>
        <Link to={"/contact"}>
          <button className={`btnPrimary ${styles.btn}`}>GET STARTED</button>
        </Link>
      </div>
      <footer className={styles.footer}>
        <div className={styles.navTop}>
          <div className={styles.navTopInnerContainer}>
            <img src={logo} alt="img" className={styles.logo} />

            {/* <button className={`body2 ${styles.joinBtn}`}>Join us</button> */}

            <form
              onSubmit={formSubmitHandler}
              className={styles.inputContainer}
            >
              <input
                disabled={isLoading}
                required
                placeholder="Enter your email"
                ref={emailRef}
              />
              <button
                type="submit"
                disabled={isLoading}
                className={`btnPrimary ${styles.subscribeBtn}`}
              >
                Contact Us <SendIcon />
              </button>
            </form>
          </div>
        </div>

        <div className={styles.footerBottom}>
          <div className={styles.footerBottomInnerContainer}>
            <div className={styles.linkContainer}>
              <div
                className={` ${styles.addressContainer} ${styles.linkCategoryContainer}`}
              >
                <p className={`body2 ${styles.linkHeading}`}>About</p>
                {addressData.map((item, j) =>
                  item.link ? (
                    <Link
                      to={item.link}
                      className={`caption ${styles.addressItemContainer}`}
                      key={j}
                    >
                      {item.icon}
                      {item.text}
                    </Link>
                  ) : (
                    <p
                      className={`caption ${styles.addressItemContainer}`}
                      key={j}
                    >
                      {item.icon}
                      {item.text}
                    </p>
                  )
                )}
              </div>

              {linksData.map((item, i) => (
                <div key={i} className={styles.linkCategoryContainer}>
                  <p className={`body2 ${styles.linkHeading}`}>
                    {item.heading}
                  </p>
                  {item.links.map((link, j) => (
                    <a key={j} className="caption" href={link.link}>
                      {link.text}
                    </a>
                  ))}
                </div>
              ))}
            </div>

            <div className={styles.socialIconsContainer}>
              {socialIcons.map((item) => (
                <a href={item.link}>{item.icon}</a>
              ))}
            </div>
          </div>
        </div>

        <div className={`caption fontRoboto ${styles.copyRightContainer}`}>
          <div className={styles.copyRightInnerContainer}>
            <p className={styles.copyRightText}>
              © Copyright 2024. All Rights Reserved by{" "}
              <span>Vueporteq IT Solutions</span>
            </p>

            <div className={styles.termsLinksContainer}>
              {copyRightLinks.map((item, i) => (
                <a key={i} href={item.link}>
                  {item.text}
                </a>
              ))}
            </div>
          </div>
        </div>
        <ToastContainer />
      </footer>
    </>
  );
};

export default Footer;
