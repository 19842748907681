import React, { useEffect, useRef, useState } from "react";
import styles from "./ContactForm.module.css";
// import img from "../../../../assets/img/homeContact.png";
import map from "./../../../assets/img/map.png";
import parse from "html-react-parser";
import SendIcon from "../../icons/SendIcon";

import "react-phone-number-input/style.css";
import PhoneInput, {
  DefaultInputComponentProps,
} from "react-phone-number-input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  AccessTime,
  DraftsOutlined,
  LocationOn,
  PhoneEnabledOutlined,
} from "@mui/icons-material";
import {
  companyEmail,
  phoneNumber,
  workingDays,
} from "../../../helper/constant";
import axios from "axios";

const points = [
  {
    icon: <LocationOn />,
    heading: "Dubai, UAE",
  },
  {
    icon: <AccessTime />,
    heading: `<b>Office Hours:</b> ${workingDays}`,
  },
  {
    icon: <DraftsOutlined />,
    heading: `<b>Email:</b> ${companyEmail}`,
  },
  {
    icon: <PhoneEnabledOutlined />,
    heading: `<b>Phone number:</b> ${phoneNumber}`,
  },
];

const ContactForm = () => {
  const [isLoading, setIsLoading] = useState(false);

  const inquiryTypeRef = useRef<HTMLInputElement | null>(null);
  const nameRef = useRef<HTMLInputElement | null>(null);
  const emailRef = useRef<HTMLInputElement | null>(null);
  const companyNameRef = useRef<HTMLInputElement | null>(null);
  const messageRef = useRef<HTMLTextAreaElement | null>(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const phoneNumberRef = useRef<any>(null);

  useEffect(() => {
    if (phoneNumberRef.current) {
      phoneNumberRef.current.required = true;
    }
  }, []);

  const clearForm = () => {
    inquiryTypeRef.current!.value = "";
    nameRef.current!.value = "";
    emailRef.current!.value = "";
    companyNameRef.current!.value = "";
    messageRef.current!.value = "";
    setPhoneNumber("");
  };

  const formSubmitHandler = (e: React.FormEvent) => {
    e.preventDefault();

    if (isLoading) return;
    const inquiryType = inquiryTypeRef.current?.value;
    const name = nameRef.current?.value;
    const email = emailRef.current?.value;
    const companyName = companyNameRef.current?.value;
    const mobile = phoneNumber;
    const message = messageRef.current?.value;

    const formBody = {
      name: name,
      email: email,
      mobile: mobile,
      aboutus: message,
      inquiryType: inquiryType,
      companyName: companyName,
    };

    console.log("formBody", formBody);

    setIsLoading(true);

    axios
      .post("https://www.vueporteq.ae/api/contactUs", formBody, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("res", res);
        setIsLoading(false);
        toast("Your message has been sent successfully!", { type: "success" });
        clearForm();
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  return (
    <section className={styles.section}>
      <div className={styles.innerContainer}>
        <form onSubmit={formSubmitHandler} className={styles.fromContainer}>
          <p className={` ${styles.aboutText}`}>Are you curious about us?</p>
          <h3 className={styles.messageText}>Send a message</h3>
          {/* <div className={styles.inputContainer}>
            <select
              disabled={isLoading}
              ref={inquiryTypeRef}
              name="inquiryType"
              className={styles.inquiryText}
            >
              <option value="">Select type of inquiry*</option>
              <option value="Select type of inquiry1">
                Select type of inquiry1
              </option>
              <option value="Select type of inquiry2">
                Select type of inquiry2
              </option>
            </select>
          </div> */}

          <div className={styles.inputContainer}>
            <input
              disabled={isLoading}
              ref={inquiryTypeRef}
              required
              type="text"
              name="inquiryType"
              placeholder="Enter type of inquiry*"
              className={styles.inputText}
            />
          </div>
          <div className={styles.inputContainer}>
            <input
              disabled={isLoading}
              ref={nameRef}
              required
              type="text"
              name="name"
              placeholder="Name"
              className={styles.inputText}
            />
          </div>
          <div className={styles.emailContainer}>
            <div className={styles.inputContainer}>
              <input
                disabled={isLoading}
                ref={emailRef}
                required
                type="email"
                name="email"
                placeholder="Email*"
                className={styles.inputText}
              />
            </div>
            <div className={styles.inputContainer}>
              <input
                disabled={isLoading}
                ref={companyNameRef}
                required
                type="text"
                name="companyName"
                placeholder="Company Name*"
                className={styles.inputText}
              />
            </div>
          </div>
          <div className={styles.emailContainer}>
            <div className={`${styles.inputContainer} ${styles.numberField}`}>
              <PhoneInput
                placeholder="Enter Phone Number"
                value={phoneNumber}
                ref={phoneNumberRef}
                limitMaxLength={true}
                onChange={setPhoneNumber}
                rules={{ required: true }}
              />
            </div>
          </div>
          <div className={styles.inputContainer}>
            <textarea
              disabled={isLoading}
              ref={messageRef}
              name="message"
              className={styles.inputContainerInput}
              placeholder=" Tell Us about your project"
            ></textarea>
          </div>
          <button disabled={isLoading} className={`btnPrimary ${styles.btn}`}>
            Send Message <SendIcon />
          </button>
        </form>

        <div className={styles.textContainer}>
          <h3 className={` ${styles.aboutTitle}`}>Schedule a Call Today</h3>
          <p
            className={`caption fontRoboto ${styles.aboutDescription} ${styles.aboutDescriptionText}`}
          >
            Schedule a call today to see how we can modernize your business and
            enhance your productivity by applying technology in the right
            quantum at the right place!
          </p>
          <h3 className={` ${styles.aboutText}`}>
            Contact Us & Let's Collaborate!
          </h3>
          <p
            className={`caption fontRoboto ${styles.aboutDescription} ${styles.aboutDescriptionText}`}
          >
            We would love to hear how we can bring your possibilities to reality
            through technology and give away 2hrs of free consulting. So call us
            now..
          </p>

          <div className={`${styles.pointsContainer} caption`}>
            {points.map((item) => (
              <div className={styles.pointItem}>
                <span className={styles.icons}>{item.icon}</span>
                <div>
                  <p className={styles.pointItemHeading}>
                    {parse(item.heading)}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <img src={map} alt="" className={styles.map} /> */}
      <ToastContainer />
    </section>
  );
};

export default ContactForm;
